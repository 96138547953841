import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { IEntityType, LocalStorageNames } from 'types'
import './styles/global.scss'
import './styles/SignUp.scss'
import 'react-responsive-modal/styles.css'
import { SignUpWrap } from './components/SignUpWrap'
import { MainRoute } from './router/route'
import { ROUTES } from './router'
import decodeToken from 'utils/decodeToken'
import { CompanyProvider } from 'store/context/CompanyContext'
import { URLs } from './urls'
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics'
import GeneralModal from 'components/GeneralModal'

const searchParams = new URLSearchParams(window.location.search)
const signupCode = searchParams.get('signup_code')
if (signupCode) {
    localStorage.setItem(LocalStorageNames.signupCode, signupCode)
}

const queryToken = searchParams.get('auth_token')
if (queryToken) {
    localStorage.setItem(LocalStorageNames.token, queryToken)
}

function App() {
    useGoogleAnalytics()

    const [title, setTitle] = useState<string>('Create account')
    const [showBackButton, setShowBackButton] = useState<boolean>(true)
    const [entityTitle, setEntityTitle] = useState<IEntityType>()
    const [showProgress, setShowProgress] = useState<boolean>(true)
    const location = useLocation()
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const { expired, data } = decodeToken(token!)

    useEffect(() => {
        if (signupCode && !location.pathname.includes(ROUTES.existingCompany.yourInformation)) {
            navigate(ROUTES.existingCompany.yourInformation)
            return
        }
        if (location.pathname.includes(ROUTES['password-reset'])) {
            localStorage.removeItem('token')
        } else if (token) {
            // if token expired
            if (expired) {
                // remove token and go to login page
                localStorage.removeItem('token')
                if (!location.pathname.includes('login')) {
                    toast('Your session has been expired, please re-login.', { type: 'error' })
                    navigate(ROUTES.login)
                }
            } else if (data && !data.verified && window.location.pathname.indexOf('verify-email') === -1) {
                // go to verification
                navigate(ROUTES.verification)
            } else if (data && data.verified) {
                // if token and !expired redirect to app.dev login
                window.location.href = `${URLs.appHostName}/auth/${token}`
            }
        }
    }, [token, expired, data?.verified, location.pathname])

    const navigateHandler = (props: string) => {
        navigate(props)
    }

    // set entity title
    useEffect(() => {
        if (location.pathname === ROUTES.base || location.pathname === ROUTES.entity) {
            setEntityTitle(undefined)
        }
    }, [location.pathname, setEntityTitle])

    const calculateProgress = (location: { pathname: string }) => {
        const basePath = `${ROUTES.base}/`
        switch (location.pathname) {
            case ROUTES.base:
                return 25
            case `${basePath}${ROUTES.company.base}`:
            case `${basePath}${ROUTES.existingCompany.base}`:
                return 40
            case `${basePath}${ROUTES.company.selectionPage}`:
            case `${basePath}${ROUTES.existingCompany.existing}`:
                return 60
            case `${basePath}${ROUTES.company.yourInformation}`:
            case `${basePath}${ROUTES.existingCompany.yourInformation}`:
                return 80
            case `${basePath}${ROUTES.verification}`:
            case `${basePath}${ROUTES.existingCompany.companyNotFound}`:
            case `${basePath}${ROUTES.company.verification}`:
            case `${basePath}${ROUTES.existingCompany.verification}`:
            case `${basePath}${ROUTES.existingCompany.admin}`:
                return 100
            default:
                return 25
        }
    }

    // set progress
    const progress = calculateProgress(location)

    return (
        <CompanyProvider>
            <div className="wrapper">
                <SignUpWrap
                    title={title}
                    progress={progress}
                    showProgress={showProgress}
                    onBack={navigateHandler}
                    showBackButton={showBackButton}
                >
                    <MainRoute
                        setTitle={setTitle}
                        navigateHandler={navigateHandler}
                        setShowProgress={setShowProgress}
                        showProgress={showProgress}
                        setShowBackButton={setShowBackButton}
                    />
                </SignUpWrap>
            </div>
            <GeneralModal />
        </CompanyProvider>
    )
}

export default App
